/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import moment from "moment";
import {LEFT_PANE, UTC_OFFSET} from "../constants";


export function formatDuration(ms: number): string {
  if (ms < 1000) {
    return `${ms / 1000} sec`;        // do not round
  }
  return `${Math.round(ms / 1000)} sec`;
}

export function formatDurationMin(ms: number): string {
  if (ms < 1000) {
    return `${ms / 1000} sec`;        // do not round
  }
  const sec = Math.round(ms / 1000);
  if (sec < 60) {
    return `${sec} sec`;
  }
  const min = Math.round(sec / 60);
  return `${min} min`;
}


export function makeInitialRange(amount: number): { created_at: number, terminated_at: number } {
  const now = moment().startOf('minute');
  if (UTC_OFFSET) now.utcOffset(UTC_OFFSET);
  return {
    created_at: now.clone().subtract(amount, 'minutes').valueOf(),
    terminated_at: now.valueOf(),
  }
}


export function capitalize(s: string): string {
  return String(s).replace(/^./, (letter) => letter.toUpperCase());
}



export interface IRoute {
  path: string | null;
  title: string;
  icon?: string;
  isNew?: boolean;
  isBeta?: boolean;
  isEntryPoint?: boolean;
}


function parseMenuURI(menuURI: string): IRoute {
  if (menuURI === '-') return {path: '-', title: '', isNew: false, isBeta: false};
  if (menuURI === '=') return {path: '=', title: '', isNew: false, isBeta: false};
  //
  const [path, query] = Array.prototype.slice.call(menuURI.match(/^(.+?)(?:\?(.*))?$/)!, 1);
  const opts = (query || '').split('&').reduce((h: any, opt: string) => opt.match(/^([A-Za-z-]+)(?:=(.*))?$/) ? {
    ...h,
    [decodeURIComponent(RegExp.$1)]: decodeURIComponent(RegExp.$2)
  } : h, {});
  const title = opts.title || path.split('/').slice(1).map(p => p[0].toUpperCase() + p.slice(1)).reverse().join(' ');

  let icon: string = opts.icon;
  try {
    if (!icon) {
      const iconFileName = path.split('/').slice(1).map(p => p[0].toUpperCase() + p.slice(1)).reverse().join('');
      icon = require(`../assets/page-icons/${iconFileName}.svg`);
    }
  } catch (err) {
    icon = '';
  }
  const isNew: boolean = 'new' in opts;
  const isBeta: boolean = 'beta' in opts;
  const isEntryPoint: boolean = 'entry-point' in opts;

  return {path, title, icon, isNew, isEntryPoint, isBeta};
}


export const LEFT_PANE_ROUTES = LEFT_PANE.map(parseMenuURI);
export const ROUTES: IRoute[] = [
  ...LEFT_PANE_ROUTES,
  {path: '/signup', title: '', icon: '', isNew: false, isBeta: false},
];


export interface IPkFontMetrics {
  fontStyle?: string;
  fontVariant?: string;
  fontWeight?: string;
  fontStretch?: string;
  fontSize?: number | string;
  lineHeight?: number | string;
  fontFamily?: string;
}

const CS: CSSStyleDeclaration = getComputedStyle(document.body);

export function setFont(ctx: CanvasRenderingContext2D, fontMetrics: IPkFontMetrics = {}) {
  const {fontStyle, fontVariant, fontWeight, fontSize, lineHeight, fontFamily} = fontMetrics;
  const strFont = [
    fontStyle || CS.fontStyle || 'normal',
    fontVariant || CS.fontVariant || 'normal',
    fontWeight || CS.fontWeight || 'normal',
    (fontSize ? (parseInt(fontSize as string) + 'px') : (CS.fontSize || 'medium')) + '/' + (lineHeight ? (parseInt(lineHeight as string) + 'px') : (CS.lineHeight || 'normal')),
    fontFamily || CS.fontFamily + ', \'CURSIVE\'' || 'sans-serif'].join(' ');
  ctx.font = strFont;
}

let __getTextWidthContext: CanvasRenderingContext2D = null;

export function getTextWidth(text: string, fontMetrics?: IPkFontMetrics): number {
  if (!__getTextWidthContext) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    __getTextWidthContext = canvas.getContext('2d');
    setFont(__getTextWidthContext, {});
  }

  if (fontMetrics) {
    setFont(__getTextWidthContext, fontMetrics);
  }

  const metrics = __getTextWidthContext.measureText(text);

  if (fontMetrics) { // reset if it was set
    setFont(__getTextWidthContext, {});
  }

  return metrics.width;
}


/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

import * as React from "react";
import "./AppFooter.scss";

const VERSION = '2021.2.3';

export const AppFooter = () => (
  <footer className="AppFooter main-footer">
      <span>Version: </span>
      <span>{VERSION}</span>
    <strong> Copyright &copy; 2017-2021 <a href="https://sip3.io"><b>SIP3</b>.IO</a></strong> All rights reserved.
  </footer>);

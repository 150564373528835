/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import * as React from "react";
import cn from "classnames";
import $ from "jquery";
import "./AppMenu.scss";
import {IRoute, LEFT_PANE_ROUTES} from "../../../common/utils";
const SVG: any = require('react-inlinesvg').default;                                                // no @types


interface INavItemProps {
  route: IRoute;
  currentRoute: IRoute | null;
  onPress?: (event: any) => void;
  children?: JSX.Element[] | JSX.Element;
}


class NavItem extends React.PureComponent<INavItemProps> {
  public render() {
    const {children, route, currentRoute, onPress} = this.props;
    let active: boolean = (route === currentRoute);
    const path: string | null = route.path;
    const icon = route.icon || '';
    const title = route.title;
    const isNew: boolean = !!route.isNew;
    const isBeta: boolean = !!route.isBeta;

    return (
      <li className={cn('NavItem nav-item', {'has-treeview menu-open': !!children})}>
        <a href={path ? '#' + path : null as any}
           className={cn('nav-link', {active})}
           onClick={onPress}>
          {(icon[0] === '/' || icon[0] === '.') ?
              <SVG className="Icon" src={icon}/> :
              <i className={cn('nav-icon fa', icon)}/> }
          <p>
            {title}
            {!!isNew && <span className="right badge badge-danger">New</span>}
            {!!isBeta && <span className="right badge badge-warning">βeta</span>}
            {!!children && <i className="right fa fa-angle-left"/>}
          </p>
        </a>
        {!!children &&
        <ul className="nav nav-treeview">
          {children}
        </ul>}
      </li>);
  }
}


interface IMainNavItemProps {
  route: IRoute;
  currentRoute: IRoute | null;
  onPressNavItem?: (route: IRoute) => boolean;
}

class MainNavItem extends React.PureComponent<IMainNavItemProps> {
  private _onPress = (event: any) => {
    const {route} = this.props;
    if (route.path === '' || route.path === '-') {
      return;
    }
    if (this.props.onPressNavItem && this.props.onPressNavItem(route)) {
      event.preventDefault();
    }
  };

  public render() {
    const {route, currentRoute} = this.props;

    if (route.path === '=') {
      return <li className="NavItem--Space"/>;
    }

    if (route.path === '-') {
      return <li className="NavItem--Separator"/>;
    }

    return (<NavItem route={route}
                     currentRoute={currentRoute}
                     onPress={this._onPress}/>);
  }
}


interface IAppMenuProps {
  appName: string;
  currentRoute: IRoute | null;
  onPressNavItem?: (route: IRoute) => boolean;
}

export class AppMenu extends React.PureComponent<IAppMenuProps> {
  public props: IAppMenuProps;
  private _treeview: HTMLElement | null;

  public constructor(props: IAppMenuProps) {
    super(props);
  }

  public componentDidMount() {
    const $treeview: any = $(this._treeview!);
    $treeview.Treeview('init');
  }

  public componentWillUnmount() {
    const $treeview: any = $(this._treeview!);
    $treeview.Treeview('destroy');
  }

  public render() {
    const {currentRoute, appName, onPressNavItem} = this.props;

    return (
      <aside className="AppMenu main-sidebar sidebar-light-primary elevation-4">
        <a href="https://www.sip3.io" className="AppMenuBrand brand-link-removed">
          <SVG src={require('../../../assets/sip3-logo.svg')}
               alt="SIP3"
               className="AppMenu__Logo brand-image"/>
          <span className="brand-text">{appName}</span>
        </a>

        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column"
                ref={ref => { this._treeview = ref as HTMLElement }}
                data-widget="treeview"
                role="menu"
                data-accordion="false">

              {LEFT_PANE_ROUTES.map((route, idx) =>
              <MainNavItem key={String(idx)}
                            route={route}
                            currentRoute={currentRoute}
                            onPressNavItem={onPressNavItem}/>)}
            </ul>
          </nav>
        </div>
      </aside>);
  }
}

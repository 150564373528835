/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

import {_BaseObservable, IDisposable} from "../common/_BaseObservable";
import {AppStateInteractor} from "./AppStateInteractor";
import {IRoute} from "../common/utils";

const DELTA_X: number = 60;
const DELTA_Y: number = 40;

const APPROXIMATE_POPUP_WIDTH = 500;
const APPROXIMATE_POPUP_HEIGHT = 500;


class PositionResolver {
  private _popupsStack: (string | null)[] = [];

  private _resolveXY(n: number): {positionX: number, positionY: number} {
    let positionX = Math.max(0, window.innerWidth / 2 - APPROXIMATE_POPUP_WIDTH / 2 - 80);
    let positionY = Math.max(0, window.innerHeight / 2 - APPROXIMATE_POPUP_HEIGHT / 2 - 60);
    for (; n > 0; n--) {
      positionX += DELTA_X;
      positionY += DELTA_Y;

      if (positionX > window.innerWidth - APPROXIMATE_POPUP_WIDTH || positionY > window.innerHeight - APPROXIMATE_POPUP_HEIGHT) {
        while (positionX > 0 && positionY > 0) {
          positionX -= DELTA_X;
          positionY -= DELTA_Y;
        }
        positionX += DELTA_X;
        positionY += DELTA_Y;
      }

    }
    return {positionX, positionY};
  }

  public reservePosition(popupId: string): {positionX: number, positionY: number} {
    const n = this._popupsStack.length;
    const {positionX, positionY} = this._resolveXY(n);
    this._popupsStack.push(popupId);
    return {positionX, positionY};
  }

  public freePosition(popupId: string): void {
    const idx = this._popupsStack.indexOf(popupId);
    if (idx !== -1) {
      this._popupsStack[idx] = null;
    }
    while (this._popupsStack.length > 0 && this._popupsStack[this._popupsStack.length - 1] === null) {
      this._popupsStack.length--;
    }
  }

  public freeAll(): void {
    this._popupsStack = [];
  }
}


export interface IPopup {
  id: string;
  positionX: number;
  positionY: number;
  title: string;
  content: any;                                                                                     // React.Element<>
  parentId?: string;
  zIndex: number;
  className?: string;
}

export interface IOpenPopup {
  id?: string;
  title: string;
  content: any;                                                                                     // React.Element<>
  parentId?: string;
  className?: string;
}


export interface IPopups {
  popupsList: IPopup[];
}


export class PopupsInteractor extends _BaseObservable<IPopups> {
  private _appStateInteractor: AppStateInteractor;
  private _currentRoute: IRoute | null;
  private _positionResolver: PositionResolver = new PositionResolver();
  private _popupCounter: number = 1;

  private constructor() {
    super({
      popupsList: [],
    });

    this._appStateInteractor = AppStateInteractor.getInstance();
    this._currentRoute = this._appStateInteractor.getModel().currentRoute;
    this._appStateInteractor.subscribeUpdates(this._onDepsUpdated);
  }

  public openPopup(openPopup: IOpenPopup): void {
    let {popupsList} = this._model;
    const popupId: string = openPopup.id || `popup_${this._popupCounter++}`;

    const p: IPopup | undefined = popupsList.find((p) => (p.id === popupId));
    if (p) {
      this.selectPopup(p.id);
      return;
    }

    const {positionX, positionY} = this._positionResolver.reservePosition(popupId);

    const maxZIndex = popupsList.reduce((n, p) => Math.max(p.zIndex, n), 0);

    // TODO: when parent - align to parent

    const popup: IPopup = {
      id: popupId,
      title: openPopup.title,
      content: openPopup.content,
      parentId: openPopup.parentId,
      positionX,
      positionY,
      zIndex: maxZIndex + 1,
      className: openPopup.className,
    };

    popupsList = [...popupsList, popup];
    this._updateModel({popupsList});
  }

  public closePopup(popupId: string): void {
    let {popupsList} = this._model;

    const toRemove = popupsList.filter(p => p.id === popupId || p.parentId === popupId);

    if (!toRemove.length) {
      console.log(`closePopup: Popup with id ${popupId} not found`);
      return;
    }

    toRemove.forEach(p => this._positionResolver.freePosition(p.id));

    popupsList = popupsList.filter(p => p.id !== popupId && p.parentId !== popupId);                // popup and its children are closed

    this._updateModel({popupsList});
  }

  public selectPopup(popupId: string): void {
    let {popupsList} = this._model;

    const p = popupsList.find((p) => (p.id === popupId));
    if (!p) {
      return;
    }

    const popupById: {[popupId: string]: IPopup} = {};
    popupsList.forEach(p => popupById[p.id] = p);                                                   // for quick search of popup

    let popupIds: string[] = popupsList.map(p => p.id);
    popupIds.sort((id1, id2) => popupById[id1].zIndex - popupById[id2].zIndex); // all the ids of popups ordered by zIndex

    // move selected popup id to the end of list
    const idx = popupIds.indexOf(popupId);
    popupIds.splice(idx, 1);
    popupIds.push(popupId);

    // move all children ids to the end of list
    const childrenIds = popupIds.filter(cid => popupById[cid].parentId === popupId);                // ids of children with correct order by zIndex
    popupIds = popupIds.filter(cid => !childrenIds.includes(cid)).concat(childrenIds);

    // due to chrome issue of canceling onClick event when dom is modified we cannot update order of popups, but only zIndexes
    popupsList = popupsList.map(p => ({...p, zIndex: popupIds.indexOf(p.id)}));

    // TODO: make calls recursive for more then one level support

    this._updateModel({popupsList});
  }

  public closeLastPopup(): void {
    if (this._model.popupsList.length) {
      this.closePopup(this._model.popupsList[this._model.popupsList.length - 1].id);
    }
  }

  private _onDepsUpdated = () => {
    const asi = this._appStateInteractor.getModel();
    if (this._currentRoute !== asi.currentRoute) {
      this._currentRoute = asi.currentRoute;
      this._updateModel({popupsList: []});                                                          // close all when navigating
      this._positionResolver.freeAll();
    }
  };

  // singleton
  private static _instance: PopupsInteractor | null = null;

  public static getInstance(): PopupsInteractor {
    if (!this._instance) {
      this._instance = new PopupsInteractor();
    }
    return this._instance;
  }

  public static openPopup(openPopup: IOpenPopup): void {
    this.getInstance().openPopup(openPopup);
  }

  public static closeLastPopup(): void {
    this.getInstance().closeLastPopup();
  }

  public static closePopup(id: string): void {
    this.getInstance().closePopup(id);
  }

  public static selectPopup(id: string): void {
    this.getInstance().selectPopup(id);
  }

  public static getModel(): IPopups {
    return this.getInstance().getModel();
  }

  public static subscribeUpdates(callback: (m: IPopups) => any): IDisposable {
    return this.getInstance().subscribeUpdates(callback);
  }

  public static unsubscribeUpdates(callback: (m: IPopups) => any): void {
    this.getInstance().unsubscribeUpdates(callback);
  }
}

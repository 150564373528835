/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

export const DEFAULT_APP_NAME = 'SIP3.IO';

export const API_PREFIX: string = process.env.API_PREFIX || '/api';

export const UTC_OFFSET = process.env.UTC_OFFSET || '';

export const LEFT_PANE: string[] = [
  '/search/simple',
  '/search/advanced?entry-point',
  '=',
  '/signout?icon=fa-sign-out&url=&title=Sign Out'
];

/*
  Restrict sip user format
  Valid values are:
  '' - any characters
  'MSISDN' - digits, '.', '*'
 */

export const SIP_USER_IDENTITY: string = (typeof process.env.SIP_USER_IDENTITY === 'string') ? process.env.SIP_USER_IDENTITY : 'MSISDN';

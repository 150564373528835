/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import * as React from 'react';
import { IPopup, IPopups, PopupsInteractor } from "../../interactors/PopupsInteractor";
import {AppNav} from "./AppNav/AppNav";
import {AppFooter} from "./AppFooter/AppFooter";
import {AppMenu} from "./AppMenu/AppMenu";
import {LoadingSpinner} from "../LoadingSpinner/LoadingSpinner";
import {Popup} from "../Popup/Popup";
import {PageAuth} from "../PageAuth/PageAuth";
import {AuthenticationInteractor, IAuthentication } from "../../interactors/AuthenticationInteractor";
import {AppStateInteractor, IAppState} from "../../interactors/AppStateInteractor";
import {DEFAULT_APP_NAME} from "../../constants";
import {IRoute} from "../../common/utils";
import "./App.scss";


export class App extends React.PureComponent<{}> {
  public props: {};
  public state: {
    popupsList: IPopup[];
    authenticated: boolean;
    currentRoute: IRoute | null;
    loading: boolean;
    authError: string | null;
    appName: string;
    page: any | null;
  };

  public constructor(props: {}) {
    super(props);

    const auth: IAuthentication = AuthenticationInteractor.getModel();
    const popups: IPopups = PopupsInteractor.getModel();
    const appState: IAppState = AppStateInteractor.getModel();

    const hostname: string = window.location.hostname;
    const appName: string = hostname.match(/(\w+)\.sip3\.io/) ? RegExp.$1 : DEFAULT_APP_NAME;
    document.title = appName;

    this.state = {                                     // TODO: collect data in AppPresenter
      ...popups,
      currentRoute: appState.currentRoute,
      page: null,
      loading: auth.loading,
      authenticated: auth.authenticated,
      authError: auth.error,
      appName,
    };

    this._onRouteUpdated(appState.currentRoute);
  }

  private _isMounted: boolean = false;

  public componentDidMount() {
    this._isMounted = true;
    AuthenticationInteractor.subscribeUpdates(this._onDepsUpdated);
    PopupsInteractor.subscribeUpdates(this._onDepsUpdated);
    AppStateInteractor.subscribeUpdates(this._onDepsUpdated);
    this._onDepsUpdated();
    document.body.addEventListener('keydown', this._onGlobalKeyDown, false);
  }

  public componentWillUnmount() {
    this._isMounted = false;
    AppStateInteractor.unsubscribeUpdates(this._onDepsUpdated);
    PopupsInteractor.unsubscribeUpdates(this._onDepsUpdated);
    AuthenticationInteractor.unsubscribeUpdates(this._onDepsUpdated);
    document.body.removeEventListener('keydown', this._onGlobalKeyDown, false);
  }

  private _onDepsUpdated = () => {
    const auth: IAuthentication = AuthenticationInteractor.getModel();
    const popups: IPopups = PopupsInteractor.getModel();
    const appState: IAppState = AppStateInteractor.getModel();

    const prevRoute = this.state.currentRoute;

    this.setState({
      ...popups,
      currentRoute: appState.currentRoute,
      loading: auth.loading,
      authenticated: auth.authenticated,
      authError: auth.error,
    });

    if (prevRoute !== appState.currentRoute) {
      this._onRouteUpdated(appState.currentRoute);
    }
  };

  private async _onRouteUpdated(route: IRoute | null) {
    // dynamic load current page code file
    let page: any = null;
    try {
      // when we got message to navigate to other page
      // first we load it (keeping previous page on the screen)
      // and only after loaded we replace the page with new one
      //
      // this timer will execute if current page is taking all resources, e.g. network resources
      // preventing a new page to be loaded. So, only if times executed, clear the state.page
      let timerId: number | null = window.setTimeout(() => {
        timerId = null;
        const appState: IAppState = AppStateInteractor.getModel();
        if (route === appState.currentRoute) {                                                          // route didn't changed
          this.setState({page: null});
        }
      }, 700);

      if (route) {
        page = (await import(`../routes${route.path}`)).default;
      }

      window.clearTimeout(timerId);
      timerId = null;
    } catch (err) {
      // 404 ?
    }

    if (this._isMounted) {
      this.setState({page});
    } else {
      this.state.page = page;
    }
  }

  private _onGlobalKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      PopupsInteractor.closeLastPopup();
    }
  };

  private _closePopup = (popupId: string) => {
    PopupsInteractor.closePopup(popupId);
  };

  private _selectPopup = (popupId: string) => {
    PopupsInteractor.selectPopup(popupId);
  };

  private _onPressNavItem = (route: IRoute) => {
    if (route.path === '/signout') {
      AuthenticationInteractor.signOut();
      return false;
    }
    return false;
  };

  private _renderBody() {
    const {popupsList, currentRoute, loading, authenticated, authError, appName, page} = this.state;

    return (
      <>
        <AppMenu currentRoute={currentRoute}
                 appName={appName}
                 onPressNavItem={this._onPressNavItem}/>
        <AppNav/>
        {page ?
          React.createElement(page, {}) :
          <section className="MainPage content-wrapper"/>}

        <AppFooter/>

        {popupsList.map((p) => (
        <Popup key={p.id}
               id={p.id}
               onClose={this._closePopup.bind(this, p.id)}
               onSelect={this._selectPopup.bind(this, p.id)}
               positionY={p.positionY}
               positionX={p.positionX}
               title={p.title}
               zIndex={p.zIndex}
               className={p.className}>
          {p.content}
        </Popup>))}
      </>);
  }

  public render() {
    const {loading, authenticated} = this.state;

    if (loading) {
      return <LoadingSpinner showSpinner={true}/>
    }

    return (
      <>
        {!authenticated &&
        <div className="App__PageAuthWrapper">
          <PageAuth/>
        </div>}
        {this._renderBody()}
      </>);
  }
}

export default App;

/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import * as React from "react";
import "./AppNav.scss";
import {AppStateInteractor, IAppState} from "../../../interactors/AppStateInteractor";

interface IAppNavProps {
}

export class AppNav extends React.PureComponent<IAppNavProps> {
  public props: IAppNavProps;
  public state: {
    pageTitle: string;
  };
  private _asi: AppStateInteractor;

  public constructor(props: IAppNavProps) {
    super(props);
    this._asi = AppStateInteractor.getInstance();
    const asim = this._asi.getModel();
    this.state = {
      pageTitle: asim.currentRoute ? asim.currentRoute.title : '',
    }
  }

  public componentDidMount() {
    this._asi.subscribeUpdates(this._onASIUpdated);
    this._onASIUpdated(this._asi.getModel());
  }

  public componentWillUnmount() {
    this._asi.unsubscribeUpdates(this._onASIUpdated);
  }

  private _onASIUpdated = (appState: IAppState) => {
    this.setState({
      pageTitle: appState.currentRoute ? appState.currentRoute.title : '',
    });
  };

  public render() {
    const {pageTitle} = this.state;

    return (
      <nav className="AppNav main-header navbar navbar-expand navbar-light border-bottom">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link"
               data-widget="pushmenu"
               href="#">
              <i className="fa fa-bars"/>
            </a>
          </li>
          <li className="AppNav__PageTitle">
            {pageTitle}
          </li>
          <li className="AppNav__PageToolbar">
          </li>
        </ul>

        {/*<ul className="navbar-nav ml-auto">*/}
          {/*<li className="nav-item dropdown">*/}
            {/*<a className="nav-link" data-toggle="dropdown" href="#">*/}
              {/*<i className="fa fa-bell-o"/>*/}
              {/*<span className="badge badge-warning navbar-badge">3</span>*/}
            {/*</a>*/}
            {/*<NotificationsDropdown/>*/}
          {/*</li>*/}
        {/*</ul>*/}
      </nav>);
  }
}

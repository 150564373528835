import * as React from "react";
import cn from "classnames";
import "./Tooltip.scss";

export type IShowTooltip = (event, content: any, i: number) => void;
export type IHideTooltip = () => void;

export interface ITooltipContext {
  showTooltip: IShowTooltip,
  hideTooltip: IHideTooltip,
}

export const TooltipContext = React.createContext<ITooltipContext>({
  showTooltip: (event, content: any) => {},
  hideTooltip: () => {},
});


export interface ITooltipProps {
  content: any;
  i: number;
  x: number;
  y: number;
}


export const Tooltip = ({content, i, x, y}: ITooltipProps) => {
  return (
    <aside className={cn('Tooltip', 'i' + i)} style={{left: x, top: y}}>
      <div className="Tooltip__Inner">
        {content}
      </div>
    </aside>);
}

/*
 *    Copyright 2019 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import {_BaseObservable, IDisposable} from "../common/_BaseObservable";
import {AuthenticationService} from "../services/AuthenticationService";


export interface IUser {
  username: string;
}


export interface IAuthentication {
  loading: boolean
  error: string | null;
  authenticated: boolean;
  user: IUser | null;
}


export class AuthenticationInteractor extends _BaseObservable<IAuthentication> {
  private _authenticationService: AuthenticationService = AuthenticationService.getInstance();

  private constructor() {
    super({
      loading: true,
      error: null,
      authenticated: false,
      user: null,
    });
    this._init();
  }

  private async _init() {
    try {
      const authenticated: boolean = await this._authenticationService.check();
      if (authenticated) {
        this._updateModel({loading: false, error: null, authenticated: true, user: {username: 'SIP3 User'}});
      } else {
        this._updateModel({loading: false, error: null, authenticated: false, user: null});
      }
    } catch (err) {
      this._updateModel({loading: false, error: String(err)});
    }
  }

  public async signIn(username: string, password: string): Promise<boolean> {
    try {
      const authResult = await this._authenticationService.signIn(username, password);
      if (authResult) {
        this._updateModel({error: null, authenticated: true, user: {username: 'SIP3 User'}});
        return true;
      } else {
        this._updateModel({error: null, authenticated: false, user: null});
        throw new Error('Invalid username or password');
      }
    } catch (err) {
      this._updateModel({error: String(err)});
      throw err;
    }
  }

  public async signOut(): Promise<boolean> {
    // ignore result
    this._updateModel({loading: false, error: null, authenticated: false, user: null});

    try {
      const authResult = await this._authenticationService.signOut();
      window.location.hash='';
      window.location.href=window.location.origin;
      if (!authResult) {
        return false;
      }
      return true;

    } catch (err) {
      console.error(err);
      return true;
    }
  }

    // call this method when authentication is expired
  public notifySignedOut(): void {
    this._updateModel({loading: false, error: null, authenticated: false, user: null});
  }

  private static _instance: AuthenticationInteractor | null = null;

  public static getInstance(): AuthenticationInteractor {
    if (!this._instance) {
      this._instance = new AuthenticationInteractor();
    }
    return this._instance;
  }

  public static signOut(): Promise<boolean> {
    return this._instance.signOut();
  }

  public static getModel(): IAuthentication {
    return this.getInstance().getModel();
  }

  public static subscribeUpdates(callback: (m: IAuthentication) => any): IDisposable {
    return this.getInstance().subscribeUpdates(callback);
  }

  public static unsubscribeUpdates(callback: (m: IAuthentication) => any): void {
    this.getInstance().unsubscribeUpdates(callback);
  }
}

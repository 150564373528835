/*
 *    Copyright 2018 SIP3.IO CORP.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */


import * as React from "react";
import cn from "classnames";
import Draggable from "react-draggable";
import "./Popup.scss";
import {IShowTooltip, ITooltipContext, ITooltipProps, Tooltip, TooltipContext} from "../Tooltip/Tooltip";
import debounce from "lodash/debounce";


const SVG: any = require('react-inlinesvg').default;                // no @types


interface IPopupProps {
  id: string;
  title: string;
  positionY: number;
  positionX: number;
  zIndex: number;
  onClose: () => void;
  onSelect: () => void;
  children?: any;
  className?: string;
}


export const PopupContext = React.createContext('');


export class Popup extends React.Component<IPopupProps> {
  public props: IPopupProps;
  public state: {
    tooltip: ITooltipProps | null;
  } = {
    tooltip: null,
  };

  public constructor(props: IPopupProps) {
    super(props);
  }

  private _onCloseHandler = (e: any) => {
    e.preventDefault();
    this.props.onClose();
  };

  private _onMouseDown = (e: any) => {
    if (e.target.closest('.Popup__X')) {
      return;
    }
    this.props.onSelect();
  };

  private _container = React.createRef<HTMLDivElement>();

  private _hideTooltip = debounce(() => this.setState({tooltip: null}), 333);

  private _showTooltip: IShowTooltip = (event, content: any, i: number) => {
    this._hideTooltip.cancel();
    let el: HTMLElement = event.target;
    const elBox = el.getBoundingClientRect();
    const cBox = this._container.current!.getBoundingClientRect();
    const x: number = Math.round(elBox.left - cBox.left + elBox.width / 2);
    const y: number = Math.round(elBox.top - cBox.top);
    this.setState({tooltip: {content, i, x, y}});
  };

  private _tooltipContext: ITooltipContext = {
    showTooltip: this._showTooltip,
    hideTooltip: this._hideTooltip,
    // hideTooltip: () => null,
  }

  public render() {
    const {positionX, positionY, zIndex, id, className} = this.props;
    const {tooltip} = this.state;

    return (
      <Draggable bounds="parent"
                 defaultClassName="Popup"
                 onMouseDown={this._onMouseDown}
                 handle=".box-title.draggable">
        <section className={cn('Popup', className)} style={{ top: positionY, left: positionX, zIndex: 1300 + zIndex }} ref={this._container}>
          <header className="Popup__Header box-header with-border">
            <h5 className="box-title draggable">{this.props.title || "Popup"}</h5>

            <div className="Popup__X" onClick={this._onCloseHandler}>
              <SVG src={require('../../assets/x.svg')}/>
            </div>
          </header>
          <TooltipContext.Provider value={this._tooltipContext}>
            <div className="box-body">
              <PopupContext.Provider value={id}>
                {this.props.children}
              </PopupContext.Provider>
            </div>
          </TooltipContext.Provider>

          { !!tooltip &&
          <Tooltip {...tooltip}/>}
        </section>
      </Draggable>);
  }
}
